.ImageWithRatio {
  display: block;
  position: relative;
  height: 0;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
