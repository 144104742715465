@import "../_variables.scss";

.AreaIntro {
  text-align: center;
  font-family: $custom-font-bold;
  width: 75%;
  max-width: 30rem;
  margin: 0 auto;
}

.AreaIntro-title {
  font-size: 48px;
  margin: 1rem 0;
}

.AreaIntro-image {
  max-width: 100%;
}

.AreaIntro-parentAreasListing {
  list-style: none;
  margin: 0;
  padding: 0;
}

.AreaIntro-parentAreasListing-item {
  display: inline-block;
  padding: 0.5rem;
}

.AreaIntro-childAreas {
  list-style: none;
  margin: 0;
  padding: 0;
}

.AreaIntro-childArea {
  display: inline-block;
}

.AreaIntro-childArea-link {
  display: block;
  padding: 0.5rem;
}
