@charset "UTF-8";
/*
Handwriting font
https://fonts.google.com/specimen/Gloria+Hallelujah
*/
@font-face {
  font-family: 'Gloria Hallelujah';
  font-style: normal;
  font-weight: 400;
  src: local("Gloria Hallelujah"), local("GloriaHallelujah"), url(https://fonts.gstatic.com/s/gloriahallelujah/v9/LYjYdHv3kUk9BMV96EIswT9DIbW-MIS11zOmvVCE.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

.ExampleComponent {
  position: relative;
  outline: 1px dotted #aaa;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px; }

.ExampleComponent {
  margin-top: 6rem; }

@keyframes targetShow {
  0% {
    outline: 0px solid transparent; }
  75% {
    outline: 10px solid #fab; }
  100% {
    outline: 2px solid #fab; } }

.ExampleComponent:target {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: targetShow; }

.ExampleComponent-meta {
  font-family: 'Gloria Hallelujah';
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%) translateY(-20px); }

.ExampleComponent-key {
  color: #999; }

.ExampleComponent-value {
  display: inline-block; }

.ExampleComponent-arrow {
  display: inline-block; }

.ExampleComponent-arrow::after {
  content: '⤵'; }

@media (min-width: 768px) {
  .ExampleComponent {
    margin-top: 0; }
  .ExampleComponent-meta {
    transform: translateX(-140%) translateY(50%) rotate(-14deg); }
  .ExampleComponent-arrow::after {
    content: '⤷'; } }
