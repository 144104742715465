@font-face {
  font-family: "URWGeometric-Bold";
  src: url("./fonts/URWGeometric-Bold.woff") format("woff");
}

@font-face {
  font-family: "URWGeometric-Regular";
  src: url("./fonts/URWGeometric-Regular.woff") format("woff");
}

@font-face {
  font-family: "URWGeometric-SemiBold";
  src: url("./fonts/URWGeometric-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "URWGeometric-ExtraBold";
  src: url("./fonts/URWGeometric-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "apercu-mono";
  src: url("./fonts/apercu-mono.woff") format("woff");
}

@font-face {
  font-family: "FrontageCondensed";
  src: url("./fonts/FrontageCondensed-3D.woff") format("woff");
}
