@import "../_variables.scss";

.SiteHeader {
  position: relative;
  line-height: 1;
  display: flex;
  flex: 1 1 50%;
  justify-content: space-between;
  padding: 20px;
  position: sticky;
  top: 0;
  height: 67px;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid $vegogo-color-background-discrete;
  z-index: 10;
}

.SiteHeader-title {
  margin: 0;
  line-height: 1;
}

.SiteHeader-titleLink {
  display: block;
  padding: 3px 0;
  line-height: 1;
}

.SiteHeader-logo {
  max-width: 110px;
  height: auto;
  display: block;
}

.SiteHeader-tagline {
  display: none;
}

.SiteHeader-nearMeLink {
  display: inline-block;
  -webkit-appearance: none;
  line-height: 1;
  padding: 0;
  margin: 0 8px 0 0;
  background: transparent;
  border: 0;
  position: relative;
  top: 0;
  margin-right: 16px;
}

.SiteHeader-navToggler {
  position: relative;
  z-index: 10;
  top: -6px;
  // right: 0;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 0.7;
  cursor: pointer;
  background-color: transparent;
  text-transform: lowercase;
  font-size: 20px;
  font-family: $custom-font-bold;
  border-bottom: 2px solid black;
}

.SiteHeader-navToggler--close {
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px 20px 5px 30px;
}

.SiteHeader-navToggler-img {
  position: relative;
  width: 20px;
  height: auto;
}

.SiteHeader-navToggler-img--close {
  width: 20px;
  // top: 10px;
  // right: 10px;
}

.SiteNav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 90%;
  overflow: hidden;
  overflow-y: scroll;
  background: $vegogo-color-green;
  z-index: 10;
  opacity: 0.95;
  text-align: right;
  padding-top: 50px;
  padding-bottom: 30px;
  padding-right: 20px;
  font-family: $custom-font-bold;
  font-size: 36px;
}

.SiteNav-navItems {
  list-style: none;
  margin: 0;
  padding: 0;

  &.SiteNav-navItems--places li {
    // margin-top: 50px;
    // margin-bottom: 50px;
  }

  &.SiteNav-navItems--site {
    margin-top: 80px;
  }

  & li {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  & a {
    text-decoration: none;
    border-bottom: 2px solid black;
    display: inline-block;
    width: auto;
  }

  & .active {
    color: #666;
  }

  &-subPlaces {
    list-style: none;
    font-size: 22px;
    //display: inline-block;
    // max-width: 400px;
    margin-top: 15px;

    & li {
      display: block;
      width: auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    & a {
      // padding: 0.25rem 0.75rem;
    }
  }
}
