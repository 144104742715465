@charset "UTF-8";
.DebugAreas-items {
  list-style: none;
  margin: 0;
  padding: 0; }

.DebugAreas-title {
  margin: 0 0 .75rem; }

.DebugAreas-item {
  margin-bottom: .75rem; }

.DebugAreas-item-title {
  margin: 0; }

.DebugAreas-item-tagline {
  margin: 0;
  font-weight: normal;
  color: #666; }
  .DebugAreas-item-tagline:before {
    content: ' – '; }

.DebugArea-childAreaList,
.DebugArea-childChildAreaList {
  margin: .75rem 0 0 .75rem;
  padding: 0; }

.DebugArea-childArea-title {
  margin: .5rem 0; }

.DebugArea-childChildAreaList > li {
  margin: 0; }

.DebugArea-childChildAreaList .DebugArea-childArea-title {
  margin: 0; }

.DebugArea-childArea-tagline {
  color: #666; }
  .DebugArea-childArea-tagline:before {
    content: ' – '; }
