@import "../_variables.scss";

.NewsletterSignup {
  background-color: $vegogo-color-green;
  font-family: $custom-font-bold;
  padding: 60px 25px;
  text-align: center;
}

.NewsletterSignup-inner {
  max-width: 30rem;
  margin: 0 auto;
}

.NewsletterSignup-introtext {
  margin: 0;
  font-size: 28px;
  line-height: 1.5;
}

.mc-fields-row {
  // display: flex;
  margin-top: 25px;
}

.mc-fields-col {
  // overflow: hidden;

  .signup-field {
    appearance: none;
    border: 0;
    background-color: #fff;
    color: #000;
    padding: 1rem 1rem 0.6rem 1rem;
    width: 90%;
    margin: 0 auto;
    line-height: 1;
    font-family: $custom-font-regular;
    text-align: center;

    &::placeholder {
      color: #aaa;
    }
  }

  .signup-button,
  .signup-field {
    font-size: 18px;
  }
}

.mc-fields-col--email {
  flex-grow: 1;
}

.NewsletterSignup-weWillBehave {
  font-family: $custom-font-regular;
}
