.ImageStack {
  padding: 60px 0 40px 0;
  overflow: hidden;
}

.ImageStack-wrap {
  position: relative;
  height: 300px;
  // background: lightyellow;
}

.ImageStack-image {
  position: absolute;
  left: 50%;
  top: 50%;
  // transform: translateX(-50%) translateY(-50%);
  // background-color: rgba(255, 255, 255, 1);
  // padding: 20px;
  // box-shadow: 3px 3px 10px 0px #ccc;
  box-shadow: 3px 3px 10px 0px rgba(72, 72, 72, 0.38);
  // border: 1px solid hsla(0, 0%, 67%, 0.2);
  //transition: all 0.5s ease-in-out;
}

.ImageStack-image-img {
  height: auto;
  width: auto;
  display: block;
}

.ImageStack + .PlaceItem-content {
  margin-top: -10px;
}
