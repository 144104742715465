@import "./_variables.scss";
@import "./themes/_fonts";
// @import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

a,
input,
.ImageStack {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: $custom-font-bold;
  margin: 0 auto;
  width: 100%;
  // max-width: 375px;
}

::selection {
  background: $vegogo-color-green;
}

input,
button,
a {
  outline-color: transparent;
  outline-width: 0;
}

// https://twitter.com/LeaVerou/status/1045768279753666562
:focus:not(:focus-visible) {
  outline-color: transparent;
}

a {
  text-decoration: underline;
  color: inherit;
}
