.Start-intro {
  margin: 80px 0; }

.Start-introText,
.Start-introImage {
  display: block;
  margin: 0 auto;
  width: 75%;
  max-width: 30rem;
  height: auto; }

.Start-introImage {
  margin-top: 80px; }
