$background-color: #f1f1f1;

.Loading {
  position: relative;
  text-align: center;
  max-width: 90%;
  margin: 20px auto;

  &-headline,
  &-image,
  &-para {
    min-height: 1rem;
    background: $background-color;
    margin: 10px auto;
  }

  &-headline {
    max-width: 45%;
    height: 2rem;
  }

  &-image {
    height: 300px;
  }

  &-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
