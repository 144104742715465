$custom-font-regular: "URWGeometric-Regular";
$custom-font-bold: "URWGeometric-Bold";
$custom-font-extrabold: "URWGeometric-ExtraBold";
$custom-font-semibold: "URWGeometric-SemiBold";
$custom-font-3d: "FrontageCondensed";

$vegogo-color-dark: #535257;
$vegogo-color-yellow: #f0bd00;
$vegogo-color-green: rgb(80, 227, 194);
$vegogo-color-red: #fd6a64;
$vegogo-color-1: #62b5b6;
$vegogo-color-background-discrete: #d8d8d8;
