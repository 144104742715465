.TextPage {
  padding: 40px;
  text-align: center;
  font-size: 17px;
  line-height: 1.75;
  max-width: 40rem;
  margin: 0 auto;

  &-Headline {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &-Image {
    width: 65%;
    max-width: 440px;
    height: auto;
  }
}

.TextPage-contact,
.TextPage-partner {
  .TextPage-preHeadline {
    font-size: 40px;
  }
  .TextPage-Headline {
    margin-top: 0;
  }
}
