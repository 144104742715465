.VegogoButton {
  border: 2px solid #000;
  line-height: 1;
  border: 4px solid #000;
  background-color: rgba(0, 0, 0, 0);
  appearance: none;
  padding: 0.95rem 2rem 0.75rem 2rem;
  line-height: 1;
  margin: 1rem auto 0 auto;
  display: block;
  position: relative;
}

.VegogoButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.VegogoButton--withIcon {
  padding-left: calc(2rem + 30px);
}

.VegogoButton-icon {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}
